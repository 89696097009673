/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { headingPrimary, subtitleText } from 'src/components/common/Typography'

import { CalculatorFormFields } from 'src/components/Calculator/CalculatorFormFields'
import { mq } from 'src/utils/mq'

const wrapper = css`
  padding: 16px;
  position: relative;

  ${mq.md} {
    padding: 64px;
  }
`

const titleStyle = css`
  ${headingPrimary}
  margin-bottom: 16px;
`

const titleBlockStyles = css`
  margin: 0 0 48px;

  ${mq.md} {
    margin: 0 0 58px;
  }
`

export const CalculatorForm: React.FC = () => {
  return (
    <div css={wrapper}>
      <div css={titleBlockStyles}>
        <h1 css={titleStyle}>
          Calculate your project
        </h1>
        <p css={subtitleText}>
          Fill in the form below and we will contact you to get all the details and provide you with
          estimation of budget and performance time
        </p>
      </div>
      <CalculatorFormFields />
    </div>
  )
}
