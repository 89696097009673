import { Calculator } from 'src/components/Calculator/Calculator'
import { ContactCard } from 'src/components/ContactCard/ContactCard'
import { Layout } from 'src/components/Layout/Layout'
import { PageProps } from 'gatsby'
import React from 'react'
import { Testimonials } from 'src/components/Testimonials/Testimonials'

const ContactUsPage: React.FC<PageProps<{}>> = () => {
  return (
    <Layout title="Calculate your project | RabbitPeepers">
      <Calculator />
      <ContactCard />
      <Testimonials />
    </Layout>
  )
}

export default ContactUsPage
