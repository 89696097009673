/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import { AgreeToTermsCheckbox } from 'src/components/common/AgreeToTermsCheckbox'
import { Button } from 'src/components/Button/Button'
import React from 'react'
import { SelectField } from 'src/components/common/SelectField'
import { TextField } from 'src/components/common/TextField'
import { mq } from 'src/utils/mq'
import { path } from 'src/utils/path'
import { useForm } from 'react-hook-form'

const formWrapperStyles = css`
  ${mq.md} {
    display: flex;
    justify-content: space-between;
  }
`

const formSectionStyles = css`
  ${mq.md} {
    flex-basis: 45%;
  }
`

type FormData = {
  description: string
  integrations: string
  projectType: string
  name: string
  email: string
  companySize: string
  agreeToTerms: boolean
}

export const CalculatorFormFields: React.FC = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>()
  const $form = React.useRef<HTMLFormElement>(null)

  const onSubmit = React.useCallback(() => {
    $form.current?.submit()
  }, [$form])

  return (
    <form
      name="calculator"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      data-netlify="true"
      data-netlify-honeypot="rp-valid-customer"
      ref={$form}
      action={path.thankYou}
      noValidate
    >
      <input type="hidden" name="form-name" value="calculator" />
      <div css={formWrapperStyles}>
        <div css={formSectionStyles}>
          <SelectField
            title="Type of the project"
            htmlFor="calculate-project-type"
            hasError={!!errors.projectType}
            defaultValue=""
            required
            {...register('projectType', { required: true })}
          >
            <option value="" disabled>
              Select option
            </option>
            <option>Custom NodeJS-bases web-service</option>
            <option>Frontend application</option>
            <option>SAAS-bases solution</option>
            <option>Headless eCommerce</option>
            <option>Infrastructure</option>
            <option>Other</option>
          </SelectField>
          <TextField
            title="Description of your project"
            htmlFor="calculate-description"
            placeholder="What the goal of your idea?"
            hasError={!!errors.description}
            rows={8}
            multiline
            required
            {...register('description', { required: true })}
          />
          <TextField
            title="What kind of integration do you have?"
            htmlFor="calculate-integrations"
            placeholder="Databases, 3rd-party APIs, migration from legacy system."
            hasError={!!errors.integrations}
            rows={6}
            multiline
            {...register('integrations', { required: false })}
          />
        </div>
        <div css={formSectionStyles}>
          <TextField
            title="Person's name to contact"
            htmlFor="calculate-name"
            placeholder="Your name"
            hasError={!!errors.name}
            required
            {...register('name', { required: true })}
          />
          <TextField
            title="Contact email"
            htmlFor="calculate-email"
            placeholder="Your email"
            type="email"
            hasError={!!errors.email}
            required
            {...register('email', { required: true, pattern: /^.+@.+\..+$/ })}
          />
          <SelectField
            title="Company's size"
            htmlFor="calculate-size"
            hasError={!!errors.companySize}
            defaultValue=""
            required
            {...register('companySize', { required: true })}
          >
            <option value="" disabled>
              Select option
            </option>
            <option>Less than 10 employees</option>
            <option>11-30</option>
            <option>31-100</option>
            <option>More than 100 employees</option>
          </SelectField>
        </div>
      </div>
      <AgreeToTermsCheckbox
        {...register('agreeToTerms', { required: true })}
        hasError={!!errors.agreeToTerms}
      />
      <Button variant="contained" size="large" type="submit">
        Submit form
      </Button>
    </form>
  )
}
