/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import { ArrowLink } from 'src/components/common/ArrowLink'
import { Squidex_LandingFlatDataDto } from 'src/types/gatsby-types'
import { motion } from 'framer-motion'

type Props = Pick<
  Squidex_LandingFlatDataDto,
  'whatDoWeDoSuffix' | 'whatDoWeDoSuffixLink'
>

const wrapper = css`
  margin: 72px 0 0;
  text-align: center;
`

export const WhatDoWeDoSuffix: React.FC<Props> = ({
  whatDoWeDoSuffix,
  whatDoWeDoSuffixLink,
}) => {
  if (!whatDoWeDoSuffixLink) {
    return null
  }

  return (
    <div css={wrapper}>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
        viewport={{ once: true }}
      >
        <ArrowLink to={whatDoWeDoSuffixLink}>{whatDoWeDoSuffix}</ArrowLink>
      </motion.div>
    </div>
  )
}
